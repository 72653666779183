@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Bold.eot');
    src: url('SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Bold.woff2') format('woff2'),
        url('SegoeUI-Bold.woff') format('woff'),
        url('SegoeUI-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI.eot');
    src: url('SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI.woff2') format('woff2'),
        url('SegoeUI.woff') format('woff'),
        url('SegoeUI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Italic.eot');
    src: url('SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Italic.woff2') format('woff2'),
        url('SegoeUI-Italic.woff') format('woff'),
        url('SegoeUI-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI Semilight';
    src: url('SegoeUI-Semilight.eot');
    src: url('SegoeUI-Semilight.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Semilight.woff2') format('woff2'),
        url('SegoeUI-Semilight.woff') format('woff'),
        url('SegoeUI-Semilight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-BoldItalic.eot');
    src: url('SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-BoldItalic.woff2') format('woff2'),
        url('SegoeUI-BoldItalic.woff') format('woff'),
        url('SegoeUI-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Light.eot');
    src: url('SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Light.woff2') format('woff2'),
        url('SegoeUI-Light.woff') format('woff'),
        url('SegoeUI-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUIBlack.eot');
    src: url('SegoeUIBlack.eot?#iefix') format('embedded-opentype'),
        url('SegoeUIBlack.woff2') format('woff2'),
        url('SegoeUIBlack.woff') format('woff'),
        url('SegoeUIBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUIBlack-Italic.eot');
    src: url('SegoeUIBlack-Italic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUIBlack-Italic.woff2') format('woff2'),
        url('SegoeUIBlack-Italic.woff') format('woff'),
        url('SegoeUIBlack-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-LightItalic.eot');
    src: url('SegoeUI-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-LightItalic.woff2') format('woff2'),
        url('SegoeUI-LightItalic.woff') format('woff'),
        url('SegoeUI-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-SemiboldItalic.eot');
    src: url('SegoeUI-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-SemiboldItalic.woff2') format('woff2'),
        url('SegoeUI-SemiboldItalic.woff') format('woff'),
        url('SegoeUI-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Semibold.eot');
    src: url('SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Semibold.woff2') format('woff2'),
        url('SegoeUI-Semibold.woff') format('woff'),
        url('SegoeUI-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI Semilight';
    src: url('SegoeUI-SemilightItalic.eot');
    src: url('SegoeUI-SemilightItalic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-SemilightItalic.woff2') format('woff2'),
        url('SegoeUI-SemilightItalic.woff') format('woff'),
        url('SegoeUI-SemilightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

