@import "./fonts/stylesheet.css";

html {
  font-family: 'Segoe UI', sans-serif;
  font-weight: 300;
  font-size: 11pt;
  color: #fff;
  overflow: hidden;

  @media (max-width: 850px) {
    font-size: 9.5pt;
  }
}

audio::-webkit-media-controls-panel {
  background-color: #e6e6e6;
}